@import "bootstrap";

/* if need header fixed */
body {
  padding-top: 5.5rem;
}
.cursor-pointer {
  cursor: pointer;
}

.w-15 {
  width: 15% !important;
}
.btn-outline-primary {
  --bs-btn-color: #0089d0;
  --bs-btn-border-color: #0089d0;
  --bs-btn-hover-bg: #0089d0;
  --bs-btn-hover-border-color: #0089d0;
  --bs-btn-active-bg: #0089d0;
  --bs-btn-active-border-color: #0089d0;
  --bs-btn-disabled-color: #0089d0;
  --bs-btn-disabled-border-color: #0089d0;
}
.btn-primary {
  --bs-btn-bg: #0089d0;
  --bs-btn-border-color: #0089d0;
  --bs-btn-hover-bg: #116896;
  --bs-btn-hover-border-color: #116896;
  --bs-btn-active-bg: #116896;
  --bs-btn-active-border-color: #116896;
  --bs-btn-disabled-bg: #0089d0;
  --bs-btn-disabled-border-color: #0089d0;
}
.manual-audiogram .css-13cymwt-control {
  border-radius: 4px 0 0 4px !important;
}
